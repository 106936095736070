import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import HomeLayout from "../HomeLayout/HomeLayout"
import CoolLightbox from "../LightBox/CoolLightBox"
const Page3 = ({onChange}) => {
  const [isOpen, setOpen] = useState(false)
  const [currentImageIndex, setCurrentIndex] = useState(0)

  const data = useStaticQuery(graphql`
    query {
      Page3: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "PostProductionPage3" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      PostProductionPageInfo3: allFile(
        sort: { order: ASC, fields: name }   
        filter: { sourceInstanceName: { eq: "PostProductionInfoPage3" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  var images = []
  data.PostProductionPageInfo3.nodes.map(img => {
    var src = img.childImageSharp.fluid.src
    images.push({
      src,
    })
  })

  return (
    <HomeLayout>
        <>
          {data.Page3.nodes.map((img, index) => {
            var idx =index
            if(idx==4||idx==5||idx==6) return <Img fluid={img.childImageSharp.fluid} />
            if(idx>6) idx=idx-3
            return (
              <div
                onClick={() => {
                  setCurrentIndex(idx)
                  setOpen(true)
                  onChange(false)
                }}
              >
                <Img fluid={img.childImageSharp.fluid} />
              </div>
            )
          })}
          <CoolLightbox 
            images={images}
            setCurrentIndex={setCurrentIndex}
            currentImageIndex={currentImageIndex}
            isOpen={isOpen}
            onClose={() => {setOpen(false)
              onChange(true)}}
          />
          
        </>
        </HomeLayout>
  )
}
export default Page3
