import React, { useState } from "react"
import Slider from "react-slick"
import { useMediaQuery } from "react-responsive"

import Layout from "../../../components/Layout/Layout"
import Page2 from "../../../components/PostProductionSlides/Page2"
import Page1 from "../../../components/PostProductionSlides/Page1"
import Page3 from "../../../components/PostProductionSlides/Page3"

const PostProduction = () => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 720px)",
  })
  const [touch , setTouch] = useState(true)
  var settings = {
    infinite: false,
    arrows: isTabletOrMobileDevice ? false : true,
    touchMove:touch,
    dots: true,
  }
  function handleChange(newValue) {
    setTouch(newValue);
  }
  return (
    <Layout >
        
        <Slider {...settings}>
        <Page1 onChange={handleChange}/>
        <Page2 onChange={handleChange}/>
        <Page3 onChange={handleChange}/>
        </Slider>
    </Layout>
  )
}
export default PostProduction
